.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

.gradient-text {
  background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(147, 7, 0, 0.898) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.swiper-container {
  width: 100%;
  height: auto;
  /* Adjust height as needed */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animate__animated {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 4s ease, transform 4s ease;
}

.animate__visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeInUp {
  from {
    transform: translateY(50px);
    /* Start 50px below */
    opacity: 0;
    /* Fully transparent */
  }

  to {
    transform: translateY(0);
    /* End at normal position */
    opacity: 1;
    /* Fully visible */
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out forwards;
}